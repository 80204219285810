import { useAuction } from '@autobid/ui/composables/useAuction'
import type { Ref } from 'vue'
import { unref, computed } from 'vue'
import type { BarometerCar, CarsListCar } from '@autobid/ui/types/Car'

export function useCurrentAuctionState(
  car?: Ref<CarsListCar | BarometerCar | undefined>
) {
  /*
    do not fetch to prevent make a request multiple times when there are cars from different auctions rendered, 
    e.g. list of searched cars - for car list it's already prefetched earlier
  */
  const { auction } = useAuction(unref(car)?.auctionId, {
    doNotFetch: true
  })

  const isSold = computed(() => {
    return unref(car)?.state === 'SOLD'
  })

  const isNotSold = computed(() => {
    return unref(car)?.state === 'NOT_SOLD'
  })

  const isUnderReservation = computed(() => {
    return unref(car)?.state === 'RESERVED'
  })

  const isUnderAuctionReservation = computed(() =>
    auction.value?.activeStatusesNames.includes('reserve_price_auction')
  )

  const isPriceDissolution = computed(() =>
    auction.value?.activeStatusesNames.includes('reserve_price_dissolution')
  )

  return computed(() => ({
    isSold: isSold.value,
    isNotSold: isNotSold.value,
    isUnderReservation: isUnderReservation.value,
    isUnderAuctionReservation: isUnderAuctionReservation.value,
    isPriceDissolution: isPriceDissolution.value
  }))
}
